import { Button, Typography, Box } from "@mui/material"
import { useRecoilState, useRecoilValue } from "recoil"
import { useClientType, useExecute, executingState, codeState } from "../store/execState"
import { useSaveDataToURL } from "../store/persistence"
import { showShareState, showGalleryState, showSettingsState } from "../store/viewState"
import { Settings } from "./Settings"
import { ShareDialog } from "./ShareDialog"

export const ActionButtons = () => {
    const clientType = useClientType()
    const execute = useExecute()
    const saveHash = useSaveDataToURL()
    const [showShare, setShowShare] = useRecoilState(showShareState)
    const code = useRecoilValue(codeState)
    const [, setShowGallery] = useRecoilState(showGalleryState)
    const [executing, setExecuting] = useRecoilState(executingState)
    const [, setShowDialog] = useRecoilState(showSettingsState)
    return <Box sx={{ borderBottom: 1, borderColor: "divider" }} display="flex">
        <Button color="inherit" sx={{ textTransform: "none" }} size="large" onClick={
            () => {
                execute()
                setExecuting(true)
                saveHash()
            }}>
            <Typography>Run</Typography>
        </Button>
        <Button
            color="inherit"
            sx={{ textTransform: "none" }}
            size="large"
            onClick={() => setShowDialog(true)}
        >
            <Typography>Settings</Typography>
        </Button>
        <Button
            color="inherit"
            sx={{ textTransform: "none" }}
            size="large"
            onClick={() => setShowShare(true)}>
            <Typography>Share</Typography>
        </Button>
        {showShare && <ShareDialog></ShareDialog>}
        {clientType === "desktop" && <Button
            color="inherit"
            sx={{ textTransform: "none" }}
            size="large"
            onClick={() => setShowGallery(true)}>
            <Typography>Gallery</Typography>
        </Button>}
        <Button
            color="inherit"
            sx={{ textTransform: "none" }}
            size="large"
            onClick={() => window.open("https://github.com/zat-dev/code-peice-doc")}>
            <Typography>About</Typography>
        </Button>
        <Settings></Settings>
    </Box >
}