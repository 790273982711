import { python } from "@codemirror/lang-python"
import { Box } from "@mui/material"
import { useRecoilState } from "recoil"
import { codeState } from "../store/execState"
import CodeMirror from '@uiw/react-codemirror';
import { useSaveDataToURL } from "../store/persistence";
export const CodeField = () => {
    const saveHash = useSaveDataToURL()
    const [code, setCode] = useRecoilState(codeState)
    return <Box sx={{ textAlign: "left", height: "100%" }} >
        <CodeMirror
            value={code}
            extensions={[python()]}
            onChange={(value, viewUpdate) => {
                setCode(value)
                saveHash()
            }}
        />
    </Box>
}