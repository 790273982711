import { ExpandLess, ExpandMore, Close } from "@mui/icons-material"
import { Alert, IconButton, Typography } from "@mui/material"
import React from "react"
import { useRecoilState } from "recoil"
import { errorState } from "../store/execState"

export const Inform = () => {
    const [err, setErr] = useRecoilState(errorState)
    const [showDetail, setShowDetail] = React.useState(false)
    if (err === null) {
        return <></>
    }
    return <Alert
        severity="error"
        sx={{ textAlign: "left" }}
        action={<>
            <IconButton size="small" onClick={() => setShowDetail(!showDetail)}>
                {showDetail ? <ExpandLess fontSize='small' /> : <ExpandMore fontSize='small' />}

            </IconButton>
            <IconButton size="small" onClick={() => { setErr(null) }}>
                <Close fontSize='small'></Close>
            </IconButton>
        </>

        }
    >
        <>
            <Typography>{err.title}</Typography>

            {showDetail && <><br /> {err.detail}</>}
        </>

    </Alert>

}
