import { Close, Search } from "@mui/icons-material";
import { Box, Card, CardContent, CardMedia, Chip, Dialog, DialogContent, DialogTitle, Grid, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import React from "react";
import { useRecoilState } from "recoil";
import { useClientType } from "../store/execState";
import amplitude from "amplitude-js"
import { showGalleryState } from "../store/viewState";

const cardData = [
    {
        img: '/images/gray.png',
        title: 'Convert an image to Grayscale',
        tags: ["pillow"],
        url: "./?state=N4Igxg9gJgpiBcIBmAnCBbABABQJIBlMBLdABwhQBdNd0BDAcxgB0A7EhzAXhvqYDoIpGKwAUAcgA2Iuv1KsG4gJRs2DFHQCeAfQ7di6Bv0isAbjCoT8ytRp0d%2BAZzrmJzstIC06rXIXKQABoQUjowAGtGGEcEAG0QoklJCAB3EABdYMo6FCZKBBB%2BAHppVll5BiCQKFTWZLooGPhY0FDKAAsC0vKFKoBXFEkC9spKUkd4IqKNFP4GIg6%2BgCM%2BxwsTShFKYwwi9CJwiEk6SU011kOSmT26R02UK7K%2FSuDwmE0EAEYAX3TvoA"
    },
    {
        img: '/images/canny.png',
        title: 'Image Edge Detection',
        tags: ["pillow"],
        url: "./?state=N4Igxg9gJgpiBcIBmAnCBbABABQJIBlMBLdABwhQBdNd0BDAcxgBob6mAxIgG0phQA6AOxKMYmALxsxAOgikYQgBQCQ3RXRmkhDVQEphoppOLsYMyEIBu%2FSipD59hsyaPmkPPiiW0xXXvwyHLgAcgAiAPoAomEA4lEAygYiZjIAznQ29igwaQCuvFo6%2BiDMIKR0YADWYmkIANrlPNwQAO4gALpllHQoTJQIIDIA9Dn5hdoMpSBQbUItdFB18PWgFZQAFoPqQpqT03ko3IMblJSkafDDo3StMgxEm3kARnlp%2FJZ8QpQWGMPoRCqEG4dG4AE93kIgcMdnR%2FnQ0l4YRoilMylUYGCEABGAC%2BHVxQA%3D%3D"
    },
    {
        img: '/images/trap.jpg',
        title: 'python default arg trap',
        tags: [],
        url: "./?state=N4Igxg9gJgpiBcJYDMAEyCuA7MAKANgLwDaAugJTwA6Wqq%2BAdAIYAOLMWUuAjOTXQCcYAFwwDa%2BGjRYCAllmG5MOXOT5YAxKiwRhqYt1KoARjDBMMAZxioWAT2EALCLSFWbKC%2Fj1MBAc2k5BSVsPDUQABoQFiYwAGsmPxhLBDIo4V8k4QQQS2EoCAxsqIKAdyx8CCYoFPgyAF8gA"
    },

    {
        img: '/images/debug.png',
        title: 'Python Debugger API sample',
        tags: ["debug", "sys.settrace"],
        url: "./?state=N4Igxg9gJgpiBcIDEACADgTwC4AsIDsVYAjAVwHMYAnFAQzQEsUBnWgWzQBsYAdfPhhwhUsLDMz59YAMxRYqtMDAD6UBszS0sYHAAppCtjAA0KGADcY%2BLKdpVyASnh8UrlA1kH2MAHTTl5JwQxLSczADaPCDKyvjeMVEAuigAhAC8KFExbLQM%2BAkILm7FVDBYpFSE8ooqahpaOkWuHmaW1ihpGQDknHkwXc6Exe6yUQAeUe6EXkZ%2BykFgocyDw25oVHlYuuMo5qGkMPAowAC%2BUX7COVszvv4LS5EgEyCJDg5NZr2yFlainShdRacTgDD7FdabfRRIGcFDSUj4MBYBgEY43OaQWA%2BSCxbxnEDvIauGBfVq%2FDrdUrlSqgonDTTMCR0kktH7tf5dGBjJRoZEEWmrVwMpklMoVKoKJSqdSabQ4ST4GQoHJ5XROIpjDrHACMRyitCiZ34QzG4W1yQyUWIUQVriV0jVKzcVPFKAALEUHYTXHxmOIfMwytUlLpg7UZQ0cISVfg1SBjCBNGAANa0SjMBDhRIJrB2ShYBAgZhYKAQUgFhOlgDu%2BCCtCgGfgWZOQA%3D"
    },

    {
        img: '/images/resize.png',
        title: 'resize image',
        tags: ["pillow"],
        url: "./?state=N4Igxg9gJgpiBcIBmAnCBbABABQJIBlMBLdABwhQBdNd0BDAcxgB0A7EhzAXhvqYDoIpGKwAUAcgA2Iuv1KsG4gJRsOAfRQwAzkQBeMbsXQN%2BmnftGiAHAAYANJltKV7Yxu16Y%2FLXQBuMCTNPfgArUkUXEDsQUjowAGtGbQQAbRiiSUkIAHcQAF1oyjoUJkoEEH4AemlWWXkGKJAoHNYsuigtVNBYygALcpq6hUaAVxRJct7KSlIteErKlDps%2FgYiPpGAIxGtGBRIVkoRSn5IdEr0IniISTpJAE9d1mvqmQu6LSOUV9q5Yej4jB7ggAIwAXzyYKAA%3D%3D%3D"
    },
    {
        img: '/images/fibonacci.png',
        title: 'fibonacci',
        tags: [],
        url: "./?state=N4Igxg9gJgpiBcICWBbADhATgFwAQGcBPfAHQDsBiAiAGwDcZcAzJAI1YjIEMwALJXAHck2XriiFuKJGFxpMEMDHz4kZAObkAcrgC8uAIwAGE%2BSL4AdPhjZMMMAFdMqzjVQiAFMYBU3gGwAlOTksEzMbJw8YEgeZAA0uNhcrDQwAfDkuFlIYWS4ADz6Bhl5WWW4dthOeWSZWRR2js5IDLhgXNZ1uDA01iXlZTmJyakA2mQAtAYAunr6uEb9AwNJKTDjU7P6LBzcYNGxUwmrqUG1pQNDJ%2BuTAExb84tdy1nXG%2Fd64btRMXfHI2lghdym8yFthmt3rMANQQsaTGZdSrVOE3aaZcjXT6jIzMLC4AD6uDUFS4GhgHi00NuAXR5yoUAguDIEDwDmsuF4DnUjFUAC8YBY5IRoEhYOIIMpmayCA40BhsOR5GpsB4dpF9jEAOwmf5rAJncogOIgNA8ADWXB5%2BAQo2mJqSmB52AQIHw2EZDhdJsZgjINAgXCgNvgdoAvkA%3D%3D%3D"
    },

    {
        img: '/images/python-powered.png',
        title: 'numpy sample',
        tags: ["numpy"],
        url: "./?state=N4Igxg9gJgpiBcICWBbADhATgFwAQDsBXdAT1wEMBnAtAHX3M03JIEZcBeGgOkeZIAUAbSGsANLgBMAXQlCAzBIAs06QEp6fFpM48tgkeKmzcCiTPX00mJPmwDaIfew616jic4mO3%2BRxvxrW3tHfR1XdxBPJm1vEF9%2Fd0CbOwcnKCgCYgAjGEwEqIoYtlwAalwjH0iAoNTHFEIAG2wkNEayIhRc%2FMjo%2FnYAKik4goCrFJCQBubW9qL%2BAr6WQfnY3Cq%2FEDUokDRyMABrcgBzGEoEIRBOtBIQWRBsRlPsBBBKbCgIQhexEE%2BAd3wjQg5Cg53gQmkAF8gA%3D"
    },
    {
        img: '/images/python-powered.png',
        title: 'clean-text usage sample',
        tags: ["clean-text"],
        url: "./?state=N4Igxg9gJgpiBcIDEBJAtgBwgJwC4EsA7AcwAJcALGUsAGxgENDyYAPXU2%2FAI2wewCeAHUIAzbBDQ16TXGw75MODnUaERIpKQDKDTPRbsRc9qQC8pISCtXSALQA%2F2fGAoAaTv2IwAzivy4AqQQojoA7gEAXjDYtExQpPE0DBgBDLTBoZQwImCyEMwhpABsAMyljs6uAHSkADIQeXIJRInMAIK0QkIArgAMAIwA7DA9qYQw1SIAFADaFLi4GD7wAPSrxBAQxPTVkGgAutMLSyvrm9u7%2B6sAlDe1AMI92NgwhGBB%2BD6khBAcgJ0ENms1nUhC0DxkhCIZGylhAJlwtgilGkakMiMIGGchFw01UTGmCLcIlIpLJonwrAA%2Bj0oZBYGYACrYHowYnMMmk3AQKkMHxgfD4JkstkkzmcCBhGLC1ns8U%2FHnPWg%2BGWijmc35Uwg9NDcGIq5mysUanlQfDEAIGkVy8WasDPV7vARUnwCXUQZWqm0mqkYWlgXBe41k14YOJgGBU5EUX3%2BwO2KzekMwMMMCNRgIxpVmIFJ0mh8OR6NanV67A54F50gFtNFzNUs0W%2BMgOFVmvp4v2l5vD4ut3cD0VgBKYxgvisdxAbhAGDTAGsGN4fAhZuBIQBaBEgA7T3BeGC4BAgPxQCA9Q%2FT09hQi0CAMKDL%2BCzA4AXyAA%3D"
    }
];




const GalleryItem = (props: (typeof cardData)[number]) => {
    return <Grid item xs={12} sm={6}>
        <Card sx={{ display: 'flex', height: 90 }}
            onClick={() => {
                window.location.replace(props.url)
                //window.location.reload()
            }}
        >
            <CardMedia
                component="img"
                sx={{ width: 80 }}
                image={props.img}
            />

            <CardContent sx={{ paddingTop: 1 }}>
                <Typography variant="body1" sx={{
                    fontWeight: "bold",
                    height: 48
                }}>
                    {props.title}
                </Typography>
                <Typography variant="body2">
                    {props.tags.map(x => <Chip label={x} size="small" />)}
                </Typography>
            </CardContent>

        </Card>
    </Grid>
}

export const Gallery = () => {
    const clientType = useClientType()
    const [showGallery, setShowGallery] = useRecoilState(showGalleryState)
    const [searchWord, setSearchWord] = React.useState("")
    const [editing, setEditing] = React.useState("")
    return <Dialog open={showGallery} fullScreen={clientType !== "desktop"} fullWidth maxWidth="md">
        <DialogTitle>Gallery

            <IconButton
                aria-label="close"
                onClick={() => setShowGallery(false)}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <Close />
            </IconButton>
        </DialogTitle>
        <DialogContent>
            <TextField
                fullWidth
                placeholder="seach word..."
                InputProps={{
                    endAdornment:
                        <InputAdornment position="start">
                            <Search onClick={() => { setSearchWord(editing); amplitude.getInstance().logEvent("search", { editing }); }}></Search>
                        </InputAdornment>,
                }}
                value={editing}
                onChange={e => setEditing(e.target.value)}
                variant="standard"
            >
            </TextField>
            <Box marginY={2}>
                <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    {cardData
                        .filter(x => x.title.includes(searchWord) ||
                            x.tags.some(y => y.includes(searchWord)))
                        .map(props => <GalleryItem {...props} key={props.url}></GalleryItem>)}
                </Grid>
            </Box>
            ...
        </DialogContent>
    </Dialog>
}