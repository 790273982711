import React, { useEffect } from 'react';
import { atom, selector, useRecoilCallback, useRecoilState, useRecoilValue } from 'recoil';
import { PyodideWorker } from "../pyodide"

import { useMediaQuery } from '@mui/material';
import amplitude from "amplitude-js"
import { clientTypeState } from './viewState';


export const executingState = atom<boolean>({
    key: 'executing',
    default: false
})


export const stdoutState = atom<string>({
    key: 'stdout',
    default: ""
});

export const stderrState = atom<string>({
    key: 'stderr',
    default: ""
});

export const pyodideState = atom<PyodideWorker | null>({
    key: 'pyodide',
    default: null
});

export const pyodideVersionState = atom<PyodideVersion>({
    key: 'pyodide-version',
    default: "v0.20.0"
});

export const errorState = atom<ErrorInfo | null>({
    key: 'error',
    default: null
});


export const packageState = atom<string[]>({
    key: 'packages',
    default: []
});

// key is used for react rendering only
export type Download = { path: string, url: string, key: number }

export const downloadsState = atom<Download[]>({
    key: 'downloads',
    default: []
});

export const codeState = atom<string>({
    key: 'code',
    default: '# write your code here and click "Run" button\n# packages can be installed by "Settings" button \n# check samples from "Gallery" button\nprint("hello world")\n'
});

export const fsState = atom<LsResult>({
    key: 'fs',
    default: {}
});

export const filesState = selector<string[]>({
    key: 'files',
    get: ({ get }) => {
        const fs = get(fsState);
        const target = get(resultShowTargetState)
        const pyodide = get(pyodideState)
        let result: string[] = []

        for (const [dirName, { dirnames, filenames }] of Object.entries(fs)) {

            for (const fileName of filenames) {
                result.push(`${dirName}/${fileName}`)
            }
        }
        if (pyodide && result.includes(target)) {
            pyodide.readFile(target)
        }
        return result
    },
});

export const resultShowTargetState = atom<string>({
    key: 'resultShowTarget',
    default: "stdout",
    effects: [({ onSet, getPromise }) => {
        onSet((target) => {
            const pyodidePromise = getPromise(pyodideState)
            const filesPromise = getPromise(filesState)
            Promise.all([pyodidePromise, filesPromise]).then(
                ([pyodide, files]) => {
                    if (pyodide && files.includes(target)) {
                        pyodide.readFile(target)
                    }
                }
            )
        })
    }]
});

export const fileContentState = atom<FileContent | null>({
    key: 'fileContent',
    default: null
});



export const useAddPackage = () => useRecoilCallback(({ set, snapshot }) => async (target: string) => {
    const pyodide = await snapshot.getPromise(pyodideState)
    if (pyodide === null) return
    pyodide.installPackage(target)
    set(packageState, (old) => [...old, target])
});

export const useRemovePackage = () => useRecoilCallback(({ set, snapshot }) => async (target: string) => {
    set(packageState, (old) => old.filter(x => x !== target))
});

export const useExecute = () => useRecoilCallback(({ set, snapshot }) => async () => {
    set(stdoutState, _ => "")
    set(stderrState, _ => "")
    set(errorState, _ => null)
    const code = await snapshot.getPromise(codeState);
    const pyodide = await snapshot.getPromise(pyodideState)
    if (pyodide === null) return
    amplitude.getInstance().logEvent("exec", {
        code,
    });
    pyodide.execute(code)
    pyodide.ls(".")
});







export const useRenewPyodide = () => useRecoilCallback(
    ({ set, snapshot }) => async () => {
        const version = await snapshot.getPromise(pyodideVersionState)
        const packages = await snapshot.getPromise(packageState)
        const downloads = await snapshot.getPromise(downloadsState)

        const pyodide = new PyodideWorker(msg => {
            switch (msg.type) {
                case "stdout": {
                    set(stdoutState, (old) => `${old}${msg.data}\n`)
                    break
                }
                case "error": {
                    set(errorState, msg.data)
                    amplitude.getInstance().logEvent("error", msg.data)
                    break
                }
                case "stderr": {
                    set(stderrState, (old) => `${old}${msg.data}\n`)
                    break
                }
                case "ls-result": {
                    set(fsState, _ => msg.data)
                    break
                }
                case "file-content": {
                    set(fileContentState, _ => msg.data)
                    break
                }
                case "exec-complete": {
                    set(executingState, _ => false)
                    break
                }
                default: {
                    ((type: never) => { })(msg)
                }
            }
        }, version)
        downloads.forEach(({ path, url }) => pyodide.download(path, url))
        packages.forEach(p => pyodide.installPackage(p))
        pyodide.ls(".")
        set(pyodideState, _ => pyodide)
        amplitude.getInstance().logEvent("renew", {
            downloads,
            packages
        });
    });

export const useClientType = () => {
    const [cleintType, setClientType] = useRecoilState(clientTypeState)
    const isMobileSize = useMediaQuery('(max-width:600px)')
    useEffect(() => {
        const isIframe = window !== window.parent
        if (isIframe) {
            setClientType("embed")
            amplitude.getInstance().logEvent("embed");
        }
        else if (isMobileSize) {
            setClientType("mobile")
        } else {
            setClientType("desktop")
        }
    }, [isMobileSize])
    return cleintType
}
