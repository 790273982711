import { Add, Close, Delete } from "@mui/icons-material"
import { Box, Typography, IconButton, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, TextField, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Chip, FormControl, FormLabel } from "@mui/material"
import React from "react"
import { useRecoilState, useRecoilValue } from "recoil"
import { downloadsState, packageState, useAddPackage, useRemovePackage, useRenewPyodide } from "../store/execState"
import { useSaveDataToURL } from "../store/persistence"
import { showSettingsState } from "../store/viewState"
import { Inform } from "./Inform"

const PkgField = () => {
    const saveHash = useSaveDataToURL()
    const packages = useRecoilValue(packageState)
    const addPackage = useAddPackage()
    const removePackage = useRemovePackage()
    const [editingPkgName, setEditingPkgName] = React.useState("")
    const AddPkg = () => {
        if (!editingPkgName) {
            return
        }
        addPackage(editingPkgName)
        setEditingPkgName("")
        saveHash()
    }
    return <>
        <FormControl>
            <FormLabel>install packages</FormLabel>
            <TextField
                fullWidth
                size='small'
                variant='standard'
                InputProps={{
                    startAdornment: packages.map(item => (
                        <Chip
                            key={item}
                            tabIndex={-1}
                            label={item}
                            onDelete={() => { removePackage(item); saveHash() }}
                            size="small"
                        />
                    )),
                    onBlur: AddPkg,
                    onChange: event => { setEditingPkgName(event.target.value); saveHash() },
                    onKeyDown: (event) => {
                        if (event.key === "Enter") {
                            AddPkg()
                            saveHash()
                        }
                        else if (event.key === "Backspace") {
                            removePackage(packages.at(-1) ?? "")
                            saveHash()
                        }
                    }
                }}
                value={editingPkgName}
            />
        </FormControl>
    </>
}

export const DownloadTable = () => {
    const [downloads, setDownloads] = useRecoilState(downloadsState)
    const [key, setKey] = React.useState(Math.max(0, ...downloads.map(x => x.key)))

    return <Box>
        <Box display={"flex"} alignItems="flex-start">
            <Typography variant="h6" marginBottom={1}>download files</Typography>
            <IconButton size="small" color="primary" onClick={() => {
                setDownloads([...downloads, { path: "", url: "", key: key + 1 }])
                setKey(key + 1)
            }}>
                <Add fontSize="small" />
            </IconButton>
        </Box>
        <TableContainer component={Paper}>
            <Table size="small">
                <TableHead>
                    <TableRow >
                        <TableCell size="small">file path</TableCell>
                        <TableCell size="small">url</TableCell>
                        <TableCell size="small"></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {downloads.map((row, i) => (
                        <TableRow key={row.key}>
                            <TableCell size="small" width={200}>
                                <TextField
                                    value={row.path}
                                    size="small"
                                    variant="standard"
                                    fullWidth
                                    onChange={e => {
                                        let newDownloads = downloads.slice(0);
                                        newDownloads[i] = { path: e.target.value, url: row.url, key: row.key }
                                        setDownloads(newDownloads)
                                    }} />
                            </TableCell>
                            <TableCell size="small">
                                <TextField
                                    value={row.url}
                                    size="small"
                                    variant="standard"
                                    fullWidth
                                    onChange={e => {
                                        let newDownloads = downloads.slice(0);
                                        newDownloads[i] = { path: row.path, url: e.target.value, key: row.key }
                                        setDownloads(newDownloads)
                                    }} />
                            </TableCell>
                            <TableCell size="small" align="right" width={50}>
                                <IconButton size="small" onClick={() => {
                                    const removed = downloads.filter((_, idx) => i !== idx)
                                    setDownloads(removed)
                                }}>
                                    <Delete fontSize="small" />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    </Box>
}


export const Settings = () => {
    const renewPyodide = useRenewPyodide()
    const [show, setShow] = useRecoilState(showSettingsState)
    return <Dialog onClose={() => setShow(false)} open={show} maxWidth="md" fullWidth >
        <DialogTitle>Settings
            <IconButton
                aria-label="close"
                onClick={() => setShow(false)}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <Close />
            </IconButton>

        </DialogTitle>
        <Inform></Inform>
        <DialogContent>
            <Stack spacing={3}>
                <PkgField></PkgField>
                <DownloadTable />
            </Stack>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => {
                setShow(false)
                renewPyodide()
            }}>Ok</Button>
        </DialogActions>
    </Dialog>
}