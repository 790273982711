import { ArrowDropDown } from "@mui/icons-material"
import { TextField, Select, MenuItem, Box, Typography, Button, Menu } from "@mui/material"
import React from "react"
import { useRecoilValue, useRecoilState } from "recoil"
import { STDOUT, STDERR } from "../constants"
import { fileContentState, resultShowTargetState, stdoutState, stderrState, filesState } from "../store/execState"
import { useSaveDataToURL } from "../store/persistence"

export const NormalFileView = () => {
    const fileContent = useRecoilValue(fileContentState)
    if (fileContent === null) {
        return <> file does not exists. please run some code </>
    }
    const { path, content } = fileContent
    if (content === null) {
        return <> file does not exists. please run some code </>
    }
    const format = path.split('.').pop()
    if (format === "txt") {
        const txt = new TextDecoder().decode(content)
        return <TextField value={txt} multiline minRows={5} fullWidth contentEditable={false}></TextField>
    }
    const url = URL.createObjectURL(
        new Blob([content], { type: `image/${format}` })
    );

    return <img src={url}></img>
}

export const EmbedFileView = () => {
    const fileContent = useRecoilValue(fileContentState)
    if (fileContent === null) {
        return <> file does not exists. please run some code </>
    }
    const { path, content } = fileContent
    if (content === null) {
        return <> file does not exists. please run some code </>
    }
    const format = path.split('.').pop()
    if (format === "txt") {
        const txt = new TextDecoder().decode(content)
        return <TextField value={txt} multiline minRows={5} fullWidth contentEditable={false}></TextField>
    }
    const url = URL.createObjectURL(
        new Blob([content], { type: `image/${format}` })
    );

    return <img src={url} style={{ width: "auto", height: "100%" }}></img>
}

export const TxtView = () => {
    const showTarget = useRecoilValue(resultShowTargetState)
    const stdout = useRecoilValue(stdoutState)
    const stderr = useRecoilValue(stderrState)
    const content = showTarget === STDOUT ? stdout : stderr
    return <TextField value={content} multiline minRows={5} fullWidth contentEditable={false}></TextField>
}

const SelectTargetButton = () => {
    const saveHash = useSaveDataToURL()
    const files = useRecoilValue(filesState)
    const [showTarget, setShowTarget] = useRecoilState(resultShowTargetState)
    const resultTargets = [STDOUT, STDERR, ...files]
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const close = () => setAnchorEl(null)
    return <>
        <Button
            color="inherit"
            sx={{ textTransform: "none" }}
            size="large"
            onClick={(e) => setAnchorEl(e.currentTarget)}>
            <Typography>{showTarget}</Typography>
            <ArrowDropDown></ArrowDropDown>
        </Button>
        <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={close}
        >
            {
                resultTargets.map(
                    v => <MenuItem
                        key={v}
                        onClick={() => {
                            setShowTarget(v)
                            saveHash()
                            close()
                        }}>
                        {v}
                    </MenuItem>
                )
            }
        </Menu>
    </>
}

export const ResultField = () => {
    const [showTarget,] = useRecoilState(resultShowTargetState)

    return <>

        <Box sx={{ borderBottom: 1, borderColor: "divider" }} display="flex">
            <SelectTargetButton></SelectTargetButton>
        </Box>
        {
            [STDOUT, STDERR].includes(showTarget) ? <TxtView /> : <NormalFileView />
        }
    </>
}