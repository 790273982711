import { atom } from "recoil"

export const showShareState = atom<boolean>({
    key: 'show-share',
    default: false
})

export const showGalleryState = atom<boolean>({
    key: 'show-gallery',
    default: false
})

export const showSettingsState = atom<boolean>({
    key: 'show-settings',
    default: false
})


export const clientTypeState = atom<"desktop" | "mobile" | "embed">({
    key: 'client',
    default: "desktop"
});

export const showLinesState = atom<[number, number] | null>({
    key: 'showLines',
    default: null
});