import { Close, ContentCopy, Search } from "@mui/icons-material"
import { Dialog, DialogTitle, IconButton, DialogContent, TextField, InputAdornment, Box, Grid, Tab, Tabs, Button, Typography, Paper } from "@mui/material"
import React from "react";
import { useRecoilState, useRecoilValue } from "recoil"
import { codeState, useClientType } from "../store/execState"
import amplitude from "amplitude-js"
import { showShareState } from "../store/viewState";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

export const ShareDialog = () => {
    const [open, setOpen] = useRecoilState(showShareState)

    const code = useRecoilValue(codeState)
    const maxLine = code.split("\n").length
    const [startLine, setStartLine] = React.useState(1);
    const [endLine, setEndLine] = React.useState(maxLine);
    const clientType = useClientType()
    const [tabNo, setTabNo] = React.useState(0);
    const [width, setWidth] = React.useState(600)
    const [height, setHeight] = React.useState(100 + maxLine * 19)
    let url = new URL(window.location.href)
    url.searchParams.set("showLines", JSON.stringify([startLine - 1, endLine]))
    const urlStr = url.toString()

    const iframeStr = `<iframe src="${urlStr}" width="${width}" height="${height}" frameborder="0" scrolling="no" allowtransparency="true"></iframe>`
    return <Dialog open={open} fullScreen={clientType !== "desktop"} fullWidth maxWidth="md">
        <DialogTitle>Share
            <IconButton
                aria-label="close"
                onClick={() => setOpen(false)}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <Close />
            </IconButton>
        </DialogTitle>
        <DialogContent>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={tabNo} onChange={(_, newValue) => setTabNo(newValue)}>
                        <Tab label="URL" />
                        <Tab label="Embedded" />
                        <Tab label="Gallery" />
                    </Tabs>
                </Box>
                <TabPanel value={tabNo} index={0}>
                    <TextField value={window.location.href} fullWidth InputProps={{
                        endAdornment:
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={() => {
                                        window.navigator.clipboard.writeText(window.location.href);
                                        amplitude.getInstance().logEvent("shareUrl")
                                    }}
                                    edge="end"
                                >
                                    <ContentCopy />
                                </IconButton>
                            </InputAdornment>
                    }} />

                </TabPanel>
                <TabPanel value={tabNo} index={1}>
                    <TextField label="iframe tag" value={iframeStr} fullWidth InputProps={{
                        endAdornment:
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={() => {
                                        window.navigator.clipboard.writeText(iframeStr)
                                        amplitude.getInstance().logEvent("shareIframe")
                                    }}
                                    edge="end"
                                >
                                    <ContentCopy />
                                </IconButton>
                            </InputAdornment>
                    }} />
                    <Box marginY={2}>
                        <Typography>Configuration</Typography>
                    </Box>

                    <Box display={"flex"} flexDirection="row">
                        <Box margin={1} sx={{ width: 100 }}>
                            <TextField
                                label="width"
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="standard"
                                value={width}
                                onChange={e => setWidth(parseInt(e.target.value))}
                            />
                        </Box>
                        <Box margin={1} sx={{ width: 100 }}>
                            <TextField
                                label="height"
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="standard"
                                value={height}
                                onChange={e => setHeight(parseInt(e.target.value))}
                            />
                        </Box>
                        <Box margin={1} sx={{ width: 100 }}>
                            <TextField
                                label="start line"
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{ inputProps: { min: 1, max: endLine } }}
                                variant="standard"
                                value={startLine}

                                onChange={e => setStartLine(parseInt(e.target.value))}
                            />
                        </Box>
                        <Box margin={1} sx={{ width: 100 }}>
                            <TextField
                                label="end line"
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{ inputProps: { min: startLine, max: maxLine } }}
                                variant="standard"
                                value={endLine}
                                onChange={e => setEndLine(parseInt(e.target.value))}
                            />
                        </Box>
                    </Box>
                    <Box>
                        <Paper>
                            <Box m={2} marginTop={4}>
                                <h1> sample result</h1>
                                <p> ... some text ...</p>
                                <p> your iframe will show the below</p>
                                {/* @ts-ignore */}
                                <iframe key={urlStr} src={urlStr} width={width} height={height} frameBorder="0" scrolling="no" allowtransparency="true"></iframe>
                                <p> your iframe will show the above</p>
                                <p> ... some text ...</p>
                            </Box>
                        </Paper>
                    </Box>
                </TabPanel>
                <TabPanel value={tabNo} index={2}>
                    <p>share on gallery needs to login</p>
                    <Button variant="contained" onClick={() => {
                        alert("sorry. work in progress")
                        amplitude.getInstance().logEvent("logintry")
                    }} >login / signup</Button>
                </TabPanel>
            </Box>
        </DialogContent >
    </Dialog >
}