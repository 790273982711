import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import { codeState } from '../../store/execState';
import { python } from "@codemirror/lang-python"
import CodeMirror, { EditorView } from '@uiw/react-codemirror';
import { useSaveDataToURL } from '../../store/persistence';


const Theme = EditorView.theme({
    "&": {
        fontSize: "10pt",
        border: "1px solid #c0c0c0"
    },
    ".cm-content": {
        fontFamily: "Menlo, Monaco, Lucida Console, monospace",
    },
    ".cm-gutters": {

    },
    ".cm-scroller": {
        overflow: "auto",
    }
});
export const MobileCodeField = () => {
    const saveHash = useSaveDataToURL()
    const [code, setCode] = useRecoilState(codeState)
    return <Box sx={{ textAlign: "left", height: "100%" }} >
        <CodeMirror
            value={code}
            extensions={[python()]}
            onChange={(value, viewUpdate) => {
                setCode(value)
                saveHash()
            }}
        />
    </Box>
};