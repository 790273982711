// define strings to avoid typo
export const TITLE = "CodePiece alpha"
export const STDOUT = "stdout"
export const STDERR = "stderr"
export const pyodideV0_20_0: PyodideVersion = "v0.20.0"
export const pyodideDev: PyodideVersion = "dev"

export const supportVersions: PyodideVersion[] = [
    pyodideV0_20_0,
    pyodideDev
] 