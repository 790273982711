import { useRecoilState, useRecoilValue } from 'recoil';

import { python } from "@codemirror/lang-python"
import CodeMirror, { EditorState, EditorView, lineNumbers } from '@uiw/react-codemirror';
import { codeState } from '../../store/execState';
import { showLinesState } from '../../store/viewState';

const Theme = EditorView.theme({
    "&": {
        fontSize: "10pt",
        border: "1px solid #c0c0c0"
    },
    ".cm-content": {
        fontFamily: "Menlo, Monaco, Lucida Console, monospace",
    },
    ".cm-gutters": {

    },
    ".cm-scroller": {
        overflow: "auto",
    }
});



export const EmbedCode = () => {
    const [code,] = useRecoilState(codeState)
    const showLines = useRecoilValue(showLinesState)
    let showCodes = code
    if (showLines) {
        const [start, end] = showLines
        showCodes = code.split("\n").slice(start, end).join("\n")
    }
    const offset = showLines ? showLines[0] : 0
    const formatLineNumber = (n: number, s: EditorState) => {
        return (n + offset).toString();
    };
    const extensions = [
        lineNumbers({ formatNumber: formatLineNumber }),
    ];

    return <CodeMirror
        editable={false}
        value={showCodes}
        extensions={[python(), EditorView.lineWrapping, Theme, extensions]}
    />
};