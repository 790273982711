import { Search } from "@mui/icons-material";
import { AppBar, Box, IconButton, Toolbar, Typography } from "@mui/material";
import { useRecoilState } from "recoil";
import { TITLE } from "../../constants";
import { showGalleryState } from "../../store/viewState";


export const MobileHeader = () => {
    const [, setShowGallery] = useRecoilState(showGalleryState)
    return <>
        <AppBar position="static" >
            <Box sx={{ flexGrow: 1 }} display="flex" justifyContent={"space-between"}>
                <Toolbar variant='dense'>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} onClick={() => { window.location.href = "/" }}>
                        {TITLE}
                    </Typography>
                </Toolbar>
                <IconButton color="inherit" onClick={() => setShowGallery(true)}>
                    <Search ></Search>
                </IconButton>
            </Box>
        </AppBar>
    </>
}
