
import { Box, CssBaseline, Grid, LinearProgress } from '@mui/material';
import { RecoilRoot, useRecoilState, useRecoilValue } from 'recoil';
import { executingState, resultShowTargetState, useClientType, useRenewPyodide } from './store/execState';

import { EmbedFileView, ResultField, TxtView } from './components/Result';
import { CodeField } from './components/CodeField';
import { Inform } from './components/Inform';
import { ActionButtons } from './components/ActionButtons';
import { DesktopHeader } from './components/DesktopHeader';
import { Gallery } from './components/Gallery';
import { EmbedCode } from './components/embed/EmbedCode';
import { EmbedActions } from './components/embed/EmbedActions';
import { MobileCodeField } from './components/mobile/MobileCodeField';
import { EmbedSelectTarget } from './components/embed/EmbedSelectShow';
import { STDOUT, STDERR } from "./constants"
import { useEffect } from 'react';
import { useRestoreFromURL } from './store/persistence';
import { MobileHeader } from './components/mobile/MobileHeader';
import Split from 'react-split'

const Embed = () => {
  const [showTarget, setShowTarget] = useRecoilState(resultShowTargetState)
  const executing = useRecoilValue(executingState)
  const txtShow = [STDOUT, STDERR].includes(showTarget)
  useEffect(() => {
    setShowTarget("code")
  }, [])
  return <Box>
    <Box sx={{ maxHeight: "calc(100vh - 35px)", width: "100%" }}>
      {showTarget === "code" && <EmbedCode />}
      {
        showTarget !== "code" && txtShow && <TxtView />
      }
      {
        showTarget !== "code" && !txtShow && <Box
          height={"calc(100vh - 35px)"}
        ><EmbedFileView /></Box>
      }
    </Box>
    {executing && <LinearProgress></LinearProgress>}
    <Box sx={{ height: "35px" }}
      display="flex"
      justifyContent="space-between"
    >
      <EmbedSelectTarget></EmbedSelectTarget>
      <EmbedActions />
    </Box>
  </Box>
}

const Main = () => {
  useRestoreFromURL()
  const renewPyodide = useRenewPyodide()
  const clientType = useClientType()
  const executing = useRecoilValue(executingState)
  useEffect(() => {
    renewPyodide()
  }, [])

  if (clientType === "embed") {
    return <Embed></Embed>
  }

  if (clientType === "mobile") {
    return <Grid container>
      <Grid xs={12} item><MobileHeader /></Grid>
      <Gallery></Gallery>
      <Grid xs={12} item>
        <ActionButtons></ActionButtons>
        {executing && <LinearProgress></LinearProgress>}
        <MobileCodeField />
        <Inform></Inform>
      </Grid>
      <Grid xs={12} item>
        <ResultField></ResultField>
      </Grid>
    </Grid>
  }

  return <Box flexDirection={"column"} height={"100vh"} display="flex">
    <Gallery></Gallery>
    <Box flexGrow={0} >
      <DesktopHeader />
    </Box>
    <Box flexGrow={1} sx={{
      height: "100%",
      '& .gutter.gutter-horizontal': {
        backgroundImage: `url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==')`,
        cursor: "col-resize"
      },
      '.gutter': {
        backgroundColor: "#eee",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "50%"
      }
    }}>
      <Split style={{ display: "flex", height: "100%" }} gutterSize={4} sizes={[65, 35]}>

        <Box flexDirection={"column"} display="flex">
          <Box flexGrow={0} >
            <ActionButtons></ActionButtons>
            {executing && <LinearProgress></LinearProgress>}
            <Inform></Inform>
          </Box>
          <Box flexGrow={1} overflow="auto" height={"0px"}>
            <CodeField />
          </Box>
        </Box>
        <Box>

          <ResultField></ResultField>
        </Box>
      </Split>
    </Box>
  </Box >
}


const App = () => {

  return (<div className="App">
    <RecoilRoot>
      <CssBaseline>
        <Main />
      </CssBaseline>
    </RecoilRoot>
  </div >
  );
}

export default App;
