
export class PyodideWorker {
    worker: Worker
    constructor(handleRecv: (msg: WorkerToMainMessage) => void, version: PyodideVersion) {
        this.worker = new Worker(new URL('./worker.ts', import.meta.url), { name: version })
        this.worker.onmessage = (msg: MessageEvent<WorkerToMainMessage>) => handleRecv(msg.data)
    }
    private postMessage(msg: MainToWorkerMessage) {
        this.worker.postMessage(msg)
    }

    execute(code: string) {
        this.postMessage({
            type: "execute",
            data: code
        })
    }
    download(path: string, url: string) {
        this.postMessage({
            type: "download",
            data: {
                path,
                url
            }
        })
    }
    installPackage(packageName: string) {
        this.postMessage({
            type: "install-package",
            data: packageName
        })
    }
    // traverse all children recursively
    ls(path: string) {
        this.postMessage({
            type: "ls",
            data: path
        })
    }
    readFile(path: string) {
        this.postMessage({
            type: "file-read",
            data: path
        })
    }

}

