import amplitude from "amplitude-js"
import * as lzString from "lz-string"
import React from "react"
import { useRecoilCallback, useRecoilState } from "recoil"
import { codeState, Download, downloadsState, packageState, resultShowTargetState } from "./execState"
import { showLinesState } from "./viewState"

class URLStore {
    static get = () => {
        if (!window.location.search) {
            return {}
        }
        const searchParams = new URLSearchParams(window.location.search)
        const hashString = searchParams.get("state") ?? ""
        console.log(lzString.decompressFromBase64(hashString))
        const hashData: Partial<HashStoreData> = JSON.parse(lzString.decompressFromBase64(hashString) ?? "{}")
        return { hashData, searchParams }
    }
    static set = (hashData: HashStoreData) => {
        const hashTargetString = JSON.stringify(hashData)
        const hash = lzString.compressToBase64(hashTargetString)
        let url = new URL(window.location.href)
        url.searchParams.set("state", hash)
        window.history.replaceState({}, '', url.toString())
    }
}

type HashStoreData = {
    code: string;
    packages: string[];
    target: string;
    downloads: Download[]
}
export const useSaveDataToURL = () => useRecoilCallback(({ snapshot }) => async () => {
    const code = await snapshot.getPromise(codeState)
    const packages = await snapshot.getPromise(packageState)
    const target = await snapshot.getPromise(resultShowTargetState)
    const downloads = await snapshot.getPromise(downloadsState)
    const hashData: HashStoreData = {
        code,
        packages,
        target,
        downloads
    }
    URLStore.set(hashData)
});


export const useRestoreFromURL = () => {
    const [, setCode] = useRecoilState(codeState)
    const [, setPackages] = useRecoilState(packageState)
    const [, setTarget] = useRecoilState(resultShowTargetState)
    const [, setShowLines] = useRecoilState(showLinesState)
    const [, setDownloads] = useRecoilState(downloadsState)
    React.useEffect(() => {
        const { hashData, searchParams } = URLStore.get()
        const code = hashData?.code
        const packages = hashData?.packages
        const target = hashData?.target
        const downloads = hashData?.downloads
        if (code) {
            setCode(code)
        }
        if (packages) {
            setPackages(packages)
        }
        if (target) {
            setTarget(target)
        }
        if (downloads) {
            setDownloads(downloads)
        }

        try {
            const [start, end] = JSON.parse(searchParams?.get("showLines") ?? "")

            setShowLines([start, end])
        } catch (_) {
            // ignore invalid data
        }
        amplitude.getInstance().logEvent("restored", {
            code,
            packages,
            target,
            downloads
        });
    }, [])
};