import { AppBar, Box, Toolbar, Typography } from "@mui/material";
import { TITLE } from "../constants";

export const DesktopHeader = () => {
    return (
        <AppBar position="static" >
            <Box sx={{ flexGrow: 1 }} display="flex" justifyContent={"space-between"}>
                <Toolbar variant='dense'>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} onClick={() => { window.location.href = "/" }}>
                        {TITLE}
                    </Typography>
                </Toolbar>
            </Box>
        </AppBar>
    );
}
