import { Edit, PlayArrow, Visibility } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import { useRecoilState } from "recoil";
import { executingState, useExecute } from "../../store/execState";

export const EmbedActions = () => {
    const execute = useExecute()
    const [executing, setExecuting] = useRecoilState(executingState)
    return <Box >
        <IconButton size="small" onClick={() => { execute(); setExecuting(true) }} disabled={executing}><PlayArrow /></IconButton>
        <IconButton size="small" onClick={() => window.open(
            "/" + window.location.search
        )} > <Edit /></IconButton>
    </Box >
}

