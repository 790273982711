import { MenuItem, Select } from "@mui/material"
import { useRecoilValue, useRecoilState } from "recoil"
import { STDOUT, STDERR } from "../../constants"
import { filesState, resultShowTargetState } from "../../store/execState"

export const EmbedSelectTarget = () => {
    const files = useRecoilValue(filesState)
    const [showTarget, setShowTarget] = useRecoilState(resultShowTargetState)
    const resultTargets = ["code", STDOUT, STDERR, ...files]
    return <Select
        value={showTarget}
        onChange={(e) => {
            setShowTarget(e.target.value)
        }}
    >
        {
            resultTargets.map(
                v => <MenuItem
                    key={v}
                    value={v}>
                    {v}
                </MenuItem>
            )
        }
    </Select>

}